import multicall from '../../utils/multicall'
import lpTokenAbi from '../../vipswap/lib/abi/uni_v2_lp.json'

const API_URL = ""
export const getCertificationPools = async ():Promise<any> => {
  const url = "https://raw.githubusercontent.com/Sitcum/staking-front-config/main/config.json"

  try {
    const response = await fetch(url,{
      // mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    if (!response.ok) {
      console.error(response)
      return {
        poolIds: {
          "BSC":[],
          "ETH":[],
          "polygon":[],
          "AVAX":[]
        },
        errorPoolIds: {
          "BSC":[],
          "ETH":[],
          "polygon":[],
          "AVAX":[]
        },
        hiddenPoolIds: {
          "BSC":[],
          "ETH":[],
          "polygon":[],
          "AVAX":[]
        },
        circulation: {
          "BSC":26242956,
          "ETH":26242956,
          "polygon":26242956,
          "AVAX":26242956
        },
        "time": "2021-12-30"
      }
    }
    const data2 = await response.json()
    return data2
  }catch (e) {
    console.error('can not get the config.json')
  }
  return {
    poolIds: {
      "BSC":[],
      "ETH":[],
      "polygon":[],
      "AVAX":[]
    },
    errorPoolIds: {
      "BSC":[],
      "ETH":[],
      "polygon":[],
      "AVAX":[]
    },
    hiddenPoolIds: {
      "BSC":[],
      "ETH":[],
      "polygon":[],
      "AVAX":[]
    },
    circulation: {
      "BSC":26242956,
      "ETH":26242956,
      "polygon":26242956,
      "AVAX":26242956
    },
    "time": "2021-12-30"
  }

}

