import React from 'react'
import { useTranslation } from 'contexts/Localization'
import { UiModalBtn } from 'views/components/UiLayout'

const ApplyForSpaceBtn: React.FC = () => {
  const { t } = useTranslation()
  return (
    <a href="https://metaverseweb3.typeform.com/TrustFi-Space" target="_blank" rel="noreferrer">
      <UiModalBtn className="width-auto">
        <h3>{t('Apply For Space')}</h3>
      </UiModalBtn>
    </a>
  )
}

export default ApplyForSpaceBtn
