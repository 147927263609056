const tokens = {
  bnb: {
    symbol: 'BNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      43114: '', // avax main
      43113: '', // avax test
      137: '', // Polygon
      80001: '', // Polygon test
      86: '', // GateChain main
      85: '', // GateChain test
      321:'', // KCC-MAINNET
      322:'', // KCC-TESTNET
      66: '', // OEC Mainnet
      65: '', // OEC Testnet
      128:'', // heco main
      256:'', // heco test
      250:'', // Fantom Opera
      4002: '', // Fantom testnet
    },
    projectLink: 'https://www.binance.com/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059fF775485246999027B3197955',
      97: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
      43114: '0xc7198437980c041c805a1edcba50c1ce5db95118', // avax main
      43113: '', // avax test
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // Polygon
      80001: '', // Polygon test
      86: '0x35f669463BABE838e3cD3dDaB41885c334c892Ef', // GateChain main
      85: '', // GateChain test
      321:'0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48', // KCC-MAINNET
      322:'', // KCC-TESTNET
      66: '0x382bb369d343125bfb2117af9c149795c6c65c50', // OEC Mainnet
      65: '', // OEC Testnet
      128:'0xa71edc38d189767582c38a3145b5873052c3e47a', // heco main
      256:'', // heco test
      250:'0x049d68029688eabf473097a2fc38ef61633a3c7a', // Fantom Opera
      4002: '', // Fantom testnet
    },
    decimals: 18,
    projectLink: '',
  },
  wbnb: {
    symbol: 'BNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0x8b1c0aa02b04104b105ada7381c70ca40e141714',
      43114: '', // avax main
      43113: '', // avax test
      137: '', // Polygon
      80001: '', // Polygon test
      86: '', // GateChain main
      85: '', // GateChain test
      321:'', // KCC-MAINNET
      322:'', // KCC-TESTNET
      66: '', // OEC Mainnet
      65: '', // OEC Testnet
      128:'', // heco main
      256:'', // heco test
      250:'', // Fantom Opera
      4002: '', // Fantom testnet
    },
    decimals: 18,
    projectLink: 'https://www.binance.com/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
      43114: '', // avax main
      43113: '', // avax test
      137: '', // Polygon
      80001: '', // Polygon test
      86: '', // GateChain main
      85: '', // GateChain test
      321:'', // KCC-MAINNET
      322:'', // KCC-TESTNET
      66: '', // OEC Mainnet
      65: '', // OEC Testnet
      128:'', // heco main
      256:'', // heco test
      250:'', // Fantom Opera
      4002: '', // Fantom testnet
    },
    decimals: 18,
    projectLink: 'https://www.binance.com/',
  },
}

export default tokens
